
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { CreateElement, VNode, RenderContext } from 'vue'
  import { DefaultProps } from 'vue/types/options'

  @Component({
    // @ts-ignore
    functional: true,
  })
  export default class OneImage extends Vue {
    name: string = 'one-cms-widget-separator'

    @Prop({
      type: String,
      default: '',
    })
    color?: string

    @Prop({
      type: String,
      default: '100%',
    })
    width?: string

    @Prop({
      type: String,
      default: '1px',
    })
    height?: string

    render(createElement: CreateElement, context: RenderContext<DefaultProps>): VNode {
      return createElement('hr', {
        style: {
          borderColor: context.props.color,
          borderWidth: context.props.height,
          width: context.props.width,
        },
      })
    }
  }
